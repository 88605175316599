<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">META</h4>
                  <p class="mb-0">Ingresa tu correo y contraseña.</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="onSubmit" role="form">
                    <div class="mb-3">
                      <argon-input v-model="userForm.email" type="email" placeholder="Email" name="email" size="lg" />
                    </div>
                    <div class="mb-3">
                      <argon-input v-model="userForm.password" type="password" placeholder="Password" name="password"
                        size="lg" />
                    </div>
                    <!-- <argon-switch id="rememberMe">Recordar</argon-switch> -->

                    <div class="text-center">
                      <argon-button type="submit" class="mt-4" variant="gradient" color="primary" fullWidth size="lg">
                        Ingresar
                      </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    <a href="/forgot-password" class="text-success text-gradient font-weight-bold">Recuperar contraseña?</a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://www.libramientoslp.mx/images/bgContacto.png');
                      background-size: cover;">
                <span class="mask bg-gradient-primary opacity-6"></span>
                <h1 class="mt-5 text-white font-weight-bolder position-relative">"META"</h1>
                <h4 class="text-white position-relative">
                  Mexicana de Técnicos en Autopistas
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import useAuth from "../composables/useAuth"
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const body = document.getElementsByTagName("body")[0];

export default {
  setup() {

    const router = useRouter()
    const { loginUser } = useAuth()

    const userForm = ref({
      email: '',
      password: ''
    })


    const seleccionaRuta = (rol) => {
      switch (rol) {
        case "Administrador":
          return 'Users';
        case "Panel Web":
          return 'Dashboard';
        case "Panel Auxilio Vial":
          return 'Alerts';
        default:
          return 'Meta'
      }
    }

    return {
      userForm,
      onSubmit: async () => {
        const res = await loginUser(userForm.value)
        if (res.data.message === 'Estas logueado') router.replace({ name: seleccionaRuta(res.data.user.rol) })
        else router.replace({ name: 'Meta' })
      }
    }
  },
  name: "signin",
  components: {
    // Navbar,
    ArgonInput,
    // ArgonSwitch,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  }
};
</script>
